import { Route, Routes } from 'react-router-dom';

import { useFeatureFlag, useNavOptionPermissions } from 'src/hooks';
import {
  CompanyListPage,
  CompanyPage,
  DUPPageProvider,
  DocumentLinkPortal,
  FDEInboxPage,
  FolderPage,
  GetStartedPage,
  IdentityVerificationLinkPortal,
  IdentityVerificationReportPage,
  IncomeCalculationPage,
  IncomeVerificationFlowPage,
  InvitationFlowPage,
  InvitationFlowPageLeasing,
  InvitationFlowPageSnappt,
  MyWorkPage,
  MyWorkProofDetailPage,
  PotentialMatchPage,
  ProgrammaticReport,
  PropertyCreatePage,
  PropertyEditPage,
  PropertyListPage,
  ReportPage,
  ReportingPage,
  ReportingReportPage,
  ResourcesPage,
  ResourcesPreviewPage,
  ReviewPage,
  SLAPage,
  TeamsCreatePage,
  TeamsEditPage,
  TeamsPage,
  TeamsPerformanceReportPage,
  TextInsertionPage,
  UserDetailPage,
  UserProfilePage,
  UsersPage
} from 'src/pages';
import { FeatureFlagKey } from 'src/ts/enums';
import LogOutSessionModal from './LogOutSessionModal';
import PrivateRoute from './PrivateRoute';
import { ROUTE_NAMES } from './navOptions';

const AuthenticatedRoutes: React.FC = () => {
  const isHelpAndResourcesUpdateEnabled = useFeatureFlag(FeatureFlagKey.HELP_AND_RESOURCES_UPDATE);

  return (
    <>
      <Routes>
        <Route
          path="/get-started"
          element={
            <PrivateRoute
              component={GetStartedPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.GET_STARTED)}
            />
          }
        />
        <Route
          path="/folder/:folderId"
          element={
            <PrivateRoute
              component={FolderPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.FR_FOLDER)}
            />
          }
        />
        <Route
          path="/folder/:folderId/proof/:proofId"
          element={
            <PrivateRoute
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.FR_TEXT_INSERTION)}
              component={TextInsertionPage}
            />
          }
        />
        <Route
          path="/properties"
          element={
            <PrivateRoute
              component={PropertyListPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.PROPERTIES)}
            />
          }
        />
        <Route
          path="/properties/create"
          element={
            <PrivateRoute
              component={PropertyCreatePage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.PROPERTIES)}
            />
          }
        />
        <Route
          path="/properties/:propertyId/edit"
          element={
            <PrivateRoute
              component={PropertyEditPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.PROPERTIES)}
            />
          }
        />
        <Route
          path="/companies"
          element={
            <PrivateRoute
              component={CompanyListPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.COMPANIES)}
            />
          }
        />
        <Route
          path="/companies/:companyId/edit"
          element={
            <PrivateRoute
              component={CompanyPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.COMPANIES)}
            />
          }
        />
        <Route
          path="/company"
          element={
            <PrivateRoute
              component={CompanyPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.MY_COMPANY)}
            />
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute
              component={UsersPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.USERS)}
            />
          }
        />
        <Route
          path="/users/invitation/create"
          element={
            <PrivateRoute
              component={InvitationFlowPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.ADD_USERS)}
            />
          }
        />
        <Route
          path="/users/invitation/create/snappt"
          element={
            <PrivateRoute
              component={InvitationFlowPageSnappt}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.ADD_USERS)}
            />
          }
        />
        <Route
          path="/users/invitation/create/leasing"
          element={
            <PrivateRoute
              component={InvitationFlowPageLeasing}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.ADD_USERS_LEASING)}
            />
          }
        />
        <Route
          path="/user/:userId"
          element={
            <PrivateRoute
              component={UserDetailPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.USERS)}
            />
          }
        />
        <Route
          path="/resources"
          element={
            <PrivateRoute
              component={
                isHelpAndResourcesUpdateEnabled
                  ? () => {
                      window.open(
                        'https://snappt.clueso.io/folders/Collection_root',
                        '_blank',
                        'noopener noreferrer'
                      );
                      return null;
                    }
                  : ResourcesPage
              }
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.RESOURCES)}
            />
          }
        />
        <Route
          path="/resources/:resourceId"
          element={
            <PrivateRoute
              component={ResourcesPreviewPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.RESOURCES)}
            />
          }
        />
        <Route
          path="/sla-tracking"
          element={
            <PrivateRoute
              component={SLAPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.SLA_TRACKING)}
            />
          }
        />
        <Route
          path="/user-profile"
          element={
            <PrivateRoute
              component={UserProfilePage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.USER_PROFILE)}
            />
          }
        />
        <Route
          path="/reporting"
          element={
            <PrivateRoute
              component={ReportingPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.REPORTING)}
            />
          }
        />
        <Route
          path="/reporting/:reportURL"
          element={
            <PrivateRoute
              component={ReportingReportPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.REPORTING)}
            />
          }
        />
        <Route
          path="/programmaticReporting"
          element={
            <PrivateRoute
              component={ProgrammaticReport}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.PROGRAMMATIC_REPORT)}
            />
          }
        />
        <Route
          path="/teams"
          element={
            <PrivateRoute
              component={TeamsPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.TEAMS)}
            />
          }
        />
        <Route
          path="/teams/edit/:teamId"
          element={
            <PrivateRoute
              component={TeamsEditPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.TEAMS)}
            />
          }
        />
        <Route
          path="/teams/create"
          element={
            <PrivateRoute
              component={TeamsCreatePage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.TEAMS_CREATE)}
            />
          }
        />
        <Route
          path="/teams/performance/:reportURL"
          element={
            <PrivateRoute
              component={TeamsPerformanceReportPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.TEAMS_PERFORMANCE)}
            />
          }
        />
        <Route
          path="/entries/:potentialId/merge/:applicationId"
          element={
            <PrivateRoute
              component={PotentialMatchPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.MATCHING)}
            />
          }
        />
        <Route
          path="/application/:entryId/report"
          element={
            <PrivateRoute
              component={ReportPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.FDE_INBOX_REPORT)}
              template="Onepage"
            />
          }
        />
        <Route
          path="/idvlinks"
          element={
            <PrivateRoute
              component={IdentityVerificationLinkPortal}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.IDENTITY_VERIFICATION_LINKS)}
            />
          }
        />
        <Route
          path="/identityVerification/:identityVerificationId/report"
          element={
            <PrivateRoute
              component={IdentityVerificationReportPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.IDENTITY_VERIFICATION_REPORT)}
              template="Onepage"
            />
          }
        />
        <Route
          path="/duplinks"
          element={
            <PrivateRoute
              component={DocumentLinkPortal}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.DOCUMENT_LINKS)}
            />
          }
        />
        <Route
          path="/application/applyonbehalf/:companyId/:propertyId"
          element={<DUPPageProvider />}
        />
        <Route
          path="/my-work"
          element={
            <PrivateRoute
              template="WidePage"
              component={MyWorkPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.MY_WORK)}
            />
          }
        />
        <Route
          path="/my-work/:folderId"
          element={
            <PrivateRoute
              template="WidePage"
              component={ReviewPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.MY_WORK)}
            />
          }
        />
        <Route
          path="/my-work/:folderId/:entryId/:proofId"
          element={
            <PrivateRoute
              template="WidePage"
              component={MyWorkProofDetailPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.MY_WORK_PROOF_DETAIL)}
            />
          }
        />
        <Route
          path="/my-work/incomeVerification/:incomeVerificationReviewId/:entryId"
          element={
            <PrivateRoute
              component={IncomeVerificationFlowPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.MY_WORK)}
              template="WidePage"
            />
          }
        />
        <Route
          path="/my-work/calculateIncome/:incomeVerificationReviewId/:entryId"
          element={
            <PrivateRoute
              component={IncomeCalculationPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.MY_WORK)}
              template="WidePage"
            />
          }
        />
        <Route
          path="/applications"
          element={
            <PrivateRoute
              component={FDEInboxPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.FDE_INBOX)}
            />
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute
              component={FDEInboxPage}
              rolesNames={useNavOptionPermissions(ROUTE_NAMES.FDE_INBOX)}
            />
          }
        />
      </Routes>
      <LogOutSessionModal />
    </>
  );
};

export default AuthenticatedRoutes;
